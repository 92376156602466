import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Gil from "../assets/images/gil-perez.jpg"

const Resume = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  
  return (
    <Layout location={location} title={siteTitle}>
      <div className="py-5 px-5 lg:px-10 bg-white">
        <h1 className="CyGroteskGrandBold text-3xl my-5 lg:mt-5 lg:mb-10">Resume</h1>

      <div className="pb-10 inline-block resumePage">
        <div className="grid grid-cols-1 gap-4 text-sm">
          <div className="grid grid-cols-4 my-5 lg:my-0 lg:grid-cols-8 gap-4">
            <div className="col-span-1">
              <a href="https://www.linkedin.com/in/gildardoperez/" target="_blank" rel="noreferrer noopener nofollow">
                <img src={Gil} className="w-full lg:w-36" alt="Gil Perez" />
              </a>
            </div>
            <div className="">
              <h2 className="text-lg font-bold">Gil Perez</h2>
              <ul>
                <li className="">perezgildardo@gmail.com</li>
                <li className="flex items-center">
                  {/* <img src={LinkedInIcon} alt="LinkedIn" className="w-5 h-5 mr-2" /> */}
                  <a href="https://www.linkedin.com/in/gildardoperez/" target="_blank" rel="noreferrer noopener nofollow" className="">https://www.linkedin.com/in/gildardoperez/</a>
                </li>
                <li className="flex items-center">
                 
                  <a href="https://www.gildardoperez.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.gildardoperez.com/</a>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="leading-normal">
              <p>Engineering Leader with a record of driving digital transformation and innovation across media, technology, and eCommerce sectors. Currently an Engineering Manager, leading AI integration and software development initiatives that align technical solutions with business objectives.  As Director of Engineering at <a href="https://www.bradient.com/" className="underline" target="_blank" rel="noreferrer noopener nofollow">BRADIENT</a>, and previously as Director of Engineering at <a href="https://www.3blackdot.com/" className="underline" target="_blank" rel="noreferrer noopener nofollow">3BLACKDOT</a>, I specialize in building scalable engineering teams, integrating advanced AI technologies, and delivering responsive, high-performing digital experiences. Leveraging a strong foundation from roles at <a href="https://www.warnerbros.com/" className="underline" target="_blank" rel="noreferrer noopener nofollow">Warner Bros.</a>, WarnerMedia, and AT&T, I am committed to innovation, strategic project management, and fostering collaborative, high-growth environments that meet the evolving needs of clients and audiences alike.</p>
          </div>

          <h2 className="text-lg font-semibold my-5">Experience</h2>

          <div className="border-gray-300 border-t py-7 leading-5">
              <div className="">
                <ul>
                  <li><strong>Ayzenberg Group</strong> <i>(February 2024 - Present)</i></li>
                  <li>Engineering Manager</li>
                  <li><a href="https://www.ayzenberg.com/" className="" target="_blank" rel="noreferrer noopener nofollow">https://www.ayzenberg.com/</a></li>
                </ul>
                <p className="my-2">Ayzenberg is a social-first, fully integrated brand acceleration practice specializing in creating and sharing brand stories for forward-thinking companies. As the Engineering Manager, I am responsible for advancing AI integration, enhancing software development processes, and aligning technical solutions with client and agency goals.</p>
                <ul className="list-disc list-inside px-5 py-5">
                  <li>Lead the integration and adoption of modern Generative AI and LLM technologies into digital marketing initiatives.</li>
                  <li>Streamlined web application development using React, Next.JS, and GraphQL, ensuring responsive and performant user experiences.</li>
                  <li>Managed and mentor a team of engineers while maintaining strategic relationships with technology vendors to ensure alignment with our goals.</li>
                  <li>Developed and optimize AI-driven content for campaigns, iterating on prompts to align with brand messaging and improve performance.</li>
                  <li>Implemented agile methodologies and enhance development processes, focusing on efficiency, scalability, and quality.</li>
                  <li>Collaborated with clients and internal teams to ensure technical solutions meet business needs, providing insights and regular updates.</li>
                </ul>
                <div className="">
                  <p className="mb-5"><strong>Responsibilities:</strong> AI Integration · Generative AI Project Management · AI Research · Engineering Leadership · Project Management · Digital Marketing Performance Optimization · Technical Account Manager · DevOps Architecture · Cross-Departmental Collaboration · Technical Mentorship · Web App Development · User Experience Optimization.</p>
                  <p><strong>Skills:</strong> OpenAI · Microsoft Copilot AI · AI Prompt Engineer · Meta Llama · Mistral AI · Claude · Stable Diffusion · Amazon Bedrock · Amazon EKS · Python · AWS · NodeJS · ReactJS · Contentful CMS</p>
                </div>
              </div>
          </div>

          <div className="border-gray-300 border-t py-7 leading-5">  
              <div className="">
                <ul>
                  <li><strong>3BLACKDOT</strong> <i>(October 2020 - January 2024)</i></li>
                  <li>Director Of Engineering</li>
                  <li><a href="https://www.3blackdot.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.3blackdot.com/</a></li>
                </ul>
                <p className="my-2">3BLACKDOT is a digital entertainment company specializing in content creation, talent management, and brand development within the gaming and esports industry. As the Director of Engineering, I'm responsible for the overall YouTube MCN and eCommerce teams, partnering with each talent for their overall monetization, sales, and talent audience development.</p>
                <ul className="list-disc list-inside px-5 py-5">
                  <li>Led and managed a team of engineers to provide technical support, development, and maintenance for over 50+ Shopify Plus stores.</li>
                  <li>Collaborated closely with clients, including internet personalities and high-profile YouTube streamers, to understand their unique requirements and deliver tailored solutions.</li>
                  <li>Developed and implemented strategic plans to ensure optimal performance, scalability, and user experience for the Shopify Plus stores.</li>
                  <li>Oversaw the end-to-end development process, including requirements gathering, coding, testing, and deployment.</li>
                  <li>Provided technical guidance and mentorship to the team, fostering a collaborative and high-performing work environment.</li>
                  <li>Conducted regular code reviews, quality assurance, and performance optimization to maintain high standards and ensure seamless operation of the Shopify Plus stores.</li>
                  <li>Acted as the main point of contact for clients, managing relationships, addressing concerns, and ensuring client satisfaction.</li>
                </ul>
              </div>
              <div className="">
                <p className="mb-5"><strong>Responsibilities:</strong> YouTube Multi-Channel Network (MCN) · Engineering Leadership · Team Director · eCommerce Management · Architecture · Engineering Management · Partner management · Digital Rights Management · Monetization and Sales · Talent Audience Development · Talent Data Aggregation.</p>
                <p className=""><strong>Skills:</strong> Shopify Plus · Shopify Hydrogen · Headless eCommerce · Headless CMS · Cloud Computing · Cloud Security · Amazon AWS · React.js · Gatsby.js · Node.js · JavaScript · Tailwind CSS · OpenAI · ElevenLabs</p>
              </div>
          </div>

          <div className="border-gray-300 border-t py-7 leading-5">
            <div className="">
              <ul>
                <li><strong>BRADIENT LLC</strong></li>
                <li>Director</li>
                <li><a href="https://www.bradient.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.bradient.com/</a></li>
              </ul>
              <p className="my-2">As a Director of Engineering at and member of the Executive Team, I am responsible for overseeing the creative design team, development team, and the overall maintenance of all clients eCommerce applications and marketing strategies.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Developed and maintained web applications utilizing cutting-edge frontend frameworks and headless architectures, ensuring optimal performance and responsive user interfaces across multiple platforms and devices.</li>
                <li>Collaborated with cross-functional teams to implement new features and enhance existing functionalities.</li>
                <li>Integrated third-party APIs and services to extend application capabilities and improve user experience.</li>
                <li>Utilized Shopify Plus to create customized eCommerce solutions for clients, including theme customization, and product catalog management.</li>
                <li>Implemented digital marketing strategies, including Facebook Business Manager and Google Ads, to drive traffic, increase conversions, and enhance brand visibility.</li>
                <li>Worked with Amazon Web Services to deploy cloud-based infrastructure and ensure scalability, reliability, and security.</li>
                <li>Engineered custom functions and advanced configurations within Hydrogen framework to enhance and extend eCommerce capabilities, resulting in improved store functionality and optimized shopping experiences.</li>
              </ul>
            </div>
            <div className="">
              <p className="mb-5"><strong>Responsibilities:</strong>  Executive Team · Engineering Leadership · eCommerce Director · Engineering Management · Klaviyo marketing · Facebook Business Manager · Google Ads · Technical SEO Specialist · CyberSecurity.</p>
              <p><strong>Skills:</strong> Shopify Plus · Shopify Hydrogen · Amazon AWS · Gatsby.js · React.js · JavaScript · Headless eCommerce · Headless CMS · Tailwind CSS · OpenAI · ElevenLabs · UI/UX Prototyping Design · Project Management</p>
            </div>
          </div>

          <div className="border-gray-300 border-t py-7 leading-5">         
            <div className="">
              <ul>
                <li><strong>Warner Bros. Entertainment Group of Companies</strong> <i>(April 2018 - April 2019)</i></li>
                <li>Full Stack Developer</li>
                <li><a href="https://www.warnerbros.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.warnerbros.com/</a></li>
                <li><a href="https://www.ellentube.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.ellentube.com/</a></li>
              </ul>
              <p className="my-2">EllenTube, created by Ellen DeGeneres and her production company, Ellen Digital Ventures, is a popular digital platform under Warner Bros. Pictures. As a Full Stack Developer, I played a key role in developing and maintaining both EllenTube web applications, and both iOS and Android platforms and EllenShop utilizing React.js, React Native and Shopify.</p>
                <ul className="list-disc list-inside px-5 py-2">
                  <li>Developed and maintained web applications using React.js and React Native ensuring efficient and responsive user interfaces.</li>
                  <li>Collaborated with a cross-functional team to implement new features and improve existing functionalities.</li>
                  <li>Integrated third-party APIs and services to enhance application capabilities.</li>
                  <li>Utilized React Native to develop mobile applications for both iOS and Android platforms.</li>
                  <li>Implemented SEO strategies and conducted digital marketing campaigns to drive organic traffic and improve search engine rankings.</li>
                  <li>Worked on Shopify Plus to create customized eCommerce solutions for clients, including theme customization, payment gateway integration, and product catalog management.</li>
                  <li>Design Ad-supported video on demand integrations</li>
                </ul>
            </div>
            <p className="my-2"><strong>Skills:</strong> React.js · React Native · Shopify · AWS · Adobe Experience Manager (AEM) · Redux · JavaScript · API Integration · UI/UX Prototyping · Ad-supported video on demand</p>
          </div>
          
          <div className="border-gray-300 border-t py-7 leading-5">
            <ul>
              <li><strong>Quantasy + Associates</strong> <i>(May 2017 - April 2018)</i></li>
              <li>Full Stack Developer</li>
              <li><a href="https://www.quantasy.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.quantasy.com/</a></li>
              <li><a href="https://laughoutloud.com" target="_blank" rel="noreferrer noopener nofollow">https://laughoutloud.com</a></li>
            </ul>
            <p className="my-2">Quantasy pioneers immersive brand experiences at the intersection of advertising, entertainment, technology, and culture. As a Full Stack Engineer, I was part of The Engineering team, where I contributed to both React.js and React Native apps for Kevin Hart's Laugh Out Loud and collaborated on the development of Steve Harvey's - Harvey's Hundreds App. Working with cross-functional teams, I aligned solutions with business goals, while implementing best practices for enhanced efficiency and high-quality standards.</p>
              <ul className="list-disc list-inside px-5 py-2">
                  <li>Developed and maintained applications using ReactJS, React Native, and Shopify Plus.</li>
                  <li>Created comprehensive solutions for businesses, improving user experience and customer engagement.</li>
                  <li>Led the full-stack development of the <a href="https://laughoutloud.com" target="_blank" className="underline" rel="noreferrer noopener nofollow">Laugh Out Loud Website</a>, optimizing performance and user experience.</li>
                  <li>Contributed to the development and maintenance of Kevin Hart's Laugh Out Loud App using ReactJS and React Native expertise.</li>
                  <li>Collaborated in a team to build and maintain Steve <a href="http://www.harveyshundreds.com/" target="_blank" className="underline" rel="noreferrer noopener nofollow">Harvey's - Harvey's Hundreds</a> App, focusing on backend optimization.</li>
                  <li>Worked with cross-functional teams, including digital marketing specialists, to align solutions with business goals.</li>
              </ul>
          <p className="my-2"><strong>Skills:</strong> React.js · React Native · AWS ·  JavaScript · API Integration · Shopify Plus</p>
          </div>
         
          <div className="border-gray-300 border-t py-7 leading-5">
            <ul>
              <li><strong>Whalerock Industries</strong> <i>(April 2015 - May 2017)</i></li>
              <li>Full Stack Developer</li>
              <li><a href="https://www.whalerockindustries.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.whalerockindustries.com/</a></li>
            </ul>
            <p className="my-2">Whalerock Industries is a pioneering media and technology company known for its digital-first, multi-platform content and technology products. Their projects consist of creating digital media hubs for high-profile celebrities like the Kardashians and Howard Stern. They have also produced popular TV shows like Hyperdrive and Battlebots.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Developed features and prototypes for web and mobile applications for high-profile clients including Kim Kardashian, Kylie Jenner, Kendall Jenner, Khloe Kardashian, and Kourtney Kardashian. Delivered content-rich experiences including makeup tutorials, live streams, video diaries, and workout routines.</li>
                <li>Played a key role in the launch of <a href="https://kyliecosmetics.com/" target="_blank" className="underline" rel="noreferrer noopener nofollow">Kylie Cosmetics</a>, utilizing Shopify Plus platform to ensure a seamless and efficient online shopping experience.</li>
                <li>Contributed to the development and enhancement of several other projects including Howard Stern, Tested.com, WonderWall.com, Mom.me, and Moviefone.com.</li>
                <li>Leveraged a variety of technologies such as NodeJS, ReactJS, React Native, EmberJS, and AWS to build and maintain scalable applications.</li>
                <li>Employed UI/UX design skills to enhance user interaction and overall user experience across multiple platforms.</li>
              </ul>
            <p className="my-2"><strong>Skills:</strong> React.js · React Native · Python · Django · Shopify · AWS · API Integration · LiveStreaming · Over-the-top (OTT) · Video Services</p>
          </div>
          
          <div className="border-gray-300 border-t py-7 leading-5">
            <ul>
              <li><strong>AOL</strong> <i>(September 2015 - December 2015)</i></li>
              <li>UI Engineer</li>
              <li><a href="https://www.moviefone.com/" target="_blank" rel="noreferrer noopener nofollow">https://www.moviefone.com/</a></li>
            </ul>
            <p className="my-2">AOL is a digital media company known for its significant online properties, including Moviefone, a comprehensive service for movie schedules, news, reviews, and ticket sales. Both AOL and Moviefone are dedicated to enhancing user experiences and delivering diverse multimedia content.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Integral member of the Engineering team, collaborated closely with the research and technology team based in New York for the successful relaunch of Moviefone.com.</li>
                <li>Developed new features and performance prototypes, improving overall user interaction and interface experience.</li>
                <li>Optimized page speed and implemented caching strategies to enhance site performance and load times.</li>
                <li>Spearheaded search engine optimization efforts to improve website visibility and reach, driving organic traffic growth.</li>
                <li>Improved specific front-end features for users, enhancing usability and customer satisfaction.</li>
                <li>Performed updates and modifications to the existing AOL Content Management System (CMS) platform, ensuring optimal functionality and seamless content management.</li>
              </ul>
          </div>
          
          <div className="border-gray-300 border-t py-7 leading-5">
            <ul>
              <li><strong>.XYZ Domain Names</strong> <i>(June 2012 - April 2015)</i></li>
              <li>Front-end Developer</li>
              <li><a href="https://gen.xyz/" target="_blank" rel="noreferrer noopener nofollow">https://gen.xyz/</a></li>
            </ul>
            <p className="my-2">XYZ.COM LLC, operator of the ".xyz" top-level domain, is a renowned domain name registry providing comprehensive and versatile domain solutions. Established in 2012 and available since 2014, the ".xyz" TLD offers unrestricted use, catering to individuals, businesses, and organizations seeking a unique and adaptable online identity. As a Frontend Developer at XYZ.COM LLC, I contributed to the development of multiple top-level domain projects, enhancing their user interface, performance, and SEO positioning.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Integral part of the team developing multiple top-level domain projects, contributing to both frontend and backend development.</li>
                <li>Collaborated with cross-functional teams to ensure seamless integration and high performance of all elements of the domains.</li>
                <li>Leveraged technical SEO knowledge to enhance website visibility, driving organic traffic and increasing user engagement.</li>
                <li>Employed UI/UX design skills to prototype designs, improving user interaction and overall user experience across multiple platforms.</li>
              </ul>
          </div>
          <br />
          <h2 className="text-lg font-semibold">Skills</h2>
          <hr className="h-1 border-gray-400" />
          <div className="">
        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Technical Proficiency</h3>
          <p className="text-gray-700 leading-relaxed">
            Expertise in building responsive and performant web applications using ReactJS, with a strong command of JavaScript/TypeScript, NodeJS, and modern frameworks like GatsbyJS and NextJS. Skilled in integrating front-end applications with RESTful and GraphQL APIs, and proficient in utilizing modern development tools and libraries.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Cloud Computing & Security</h3>
          <p className="text-gray-700 leading-relaxed">
            Certified in Amazon Web Services (AWS) and Cloud Security, with extensive experience in cloud-based infrastructure and cybersecurity.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Engineering Leadership</h3>
          <p className="text-gray-700 leading-relaxed">
            Extensive experience in leading engineering teams, guiding individuals in technical excellence, and project management. Proven ability to foster cross-functional collaboration across organizational units and mentor teams to achieve high standards in code quality and maintainability.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Web Design & UI/UX</h3>
          <p className="text-gray-700 leading-relaxed">
            Proficient in Graphic Design, Motion Graphics, and UI/UX Prototyping, with a keen eye for design and the ability to create experiences aligned with design mocks to enhance user interaction and engagement.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">SEO/SEM Expertise</h3>
          <p className="text-gray-700 leading-relaxed">
            Skilled in Technical SEO, website optimization, and SEO strategy development to boost online visibility and drive traffic.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Digital Marketing</h3>
          <p className="text-gray-700 leading-relaxed">
            Expertise in content strategy, keyword research, backlink generation, and leveraging analytics tools like Google Trends and Google Analytics for data-driven decisions.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">eCommerce Management</h3>
          <p className="text-gray-700 leading-relaxed">
            Specialized in managing eCommerce platforms, especially Shopify Plus, focusing on user experience and SEO optimization.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Software Development</h3>
          <p className="text-gray-700 leading-relaxed">
            Knowledgeable in Ruby on Rails, TailwindCSS, and CSS3, contributing to robust and visually appealing web applications.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Artificial Intelligence & Machine Learning</h3>
          <p className="text-gray-700 leading-relaxed">
            Familiar with OpenAI, Whisper, Meta Llama 2, Mistral AI, Claude 2, and generative AI technologies, including text-to-speech and large language models (LLMs), LLama2 with Amazon Bedrock, OpenCV for face detection, and FFMPEG for video processing.
          </p>
        </div>

        <div className="space-y-2 mb-5">
          <h3 className=" font-bold text-gray-800">Client Relationship Management</h3>
          <p className="text-gray-700 leading-relaxed">
            Skilled in building and maintaining strong client relationships, ensuring alignment with business objectives and satisfaction with deliverables.
          </p>
        </div>


          </div>
      </div>
      </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Resume | Experience and Expertise in AI and Emerging Technologies" />

export default Resume

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
